import { getAuthHeader, paramBuilder } from "../services/utils";
const axios = require("axios");

export async function getEventPages(params) {
    params.is_hide = "0";
    const queryString = paramBuilder(params);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/event-page" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getEventPageBySlug(slug) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/event-page/" + slug;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getPageBySlug(slug) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/page/" + slug;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function getPages(params) {
    const queryString = paramBuilder(params);

    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/page" + queryString;
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error?.message);
                }
            });
    });
}
