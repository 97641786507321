// react
import React, { useState, useEffect, Suspense, lazy } from "react";

// third-party
import { Link, useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import * as GENERAL from "../../api/general";
import { showGeneralError } from "../../services/utils";
import BlockLoader from "../blocks/BlockLoader";
import Cookies from "universal-cookie";
import moment from "moment";
import { shippingTypeList } from "../../data/generalData";
import classnames from "classnames";

const BlockAnnouncementCarousel = lazy(() => import("../blocks/BlockAnnouncementCarousel"));

export default function CustomTopbar(props) {
    const { miscInfo, announcements } = props;
    const history = useHistory()
    const [modalVisible, setModalVisible] = useState(false);
    let searchParams = new URLSearchParams(history.location.search)
    let branchFromURL = searchParams.get("branchID") ?? null
    const [selectedBranch, setSelectedBranch] = useState(branchFromURL ? branchFromURL : localStorage.getItem("branchid") ? localStorage.getItem("branchid") : "");
    const [selectedShippingType, setSelectedShippingType] = useState(localStorage.getItem("shippingType") ? localStorage.getItem("shippingType") : "2");
    const [branchReload, setBranchReload] = useState(localStorage.getItem("branchReload") ? localStorage.getItem("branchReload") : 0);
    const [branches, setBranches] = useState([]);
    const [pickupBranches, setPickupBranches] = useState([]);
    const [apiLoading, setApiLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [branchPopUp, setBranchPopUp] = useState(false);
    const [shippingType, setShippingType] = useState(localStorage.getItem("shippingType") ? localStorage.getItem("shippingType") : "2"); // 1 = pickup, 2 = delivery
    const [shippingTypeOption, setShippingTypeOption] = useState(shippingTypeList);
    const cookies = new Cookies();

    useEffect(() => {
        if (branchPopUp && !modalVisible) {            
            if (announcements?.length){
                setPopUp(announcements);
                setBranchPopUp(false);
            } 
        }
    }, [announcements, branchPopUp, modalVisible]);

    function setPopUp(announcement) {
        let previousAnnoucement = cookies.get("announcement_pop_up");
        if (previousAnnoucement == undefined || previousAnnoucement < announcement.length) {
            setModalShow(true);
        }
        cookies.set("announcement_pop_up", announcement?.length, {
            path: "/",
            expires: moment().add(1, "days").utc()._d,
        });
    }

    function renderAnnouncementModal(props) {
        return (
            <Suspense
                fallback={
                    <div className="block-empty-page">
                        <BlockLoader />
                    </div>
                }
            >
                <Modal size="lg" isOpen={modalShow} toggle={() => setModalShow(false)} centered scrollable>
                    <ModalHeader toggle={() => setModalShow(false)}></ModalHeader>
                    <ModalBody>
                        <BlockAnnouncementCarousel announcements={announcements} />
                    </ModalBody>
                </Modal>
            </Suspense>
        );
    }

    useEffect(() => {
        setApiLoading(true);

        let params = {};
        params.per_page = 999;
        params.sort_by = "created_at";
        params.sort_type = "asc";
        params.status = "10";
        GENERAL.getBranchList(params)
            .then((res) => {
                setBranches(res.data.data);
                setPickupBranches((res.data.data).filter(branch => branch?.additional?.allow_self_pickup == true));
                setBranchPopUp(true);
                if (res.data.data.length == 0) {
                    handleBranchChange("", true);
                } else {
                    if (branchReload == 1) {
                        setModalVisible(false);
                        localStorage.setItem("branchReload", 0);
                    } else {
                        setModalVisible(true);
                    }
                    if (!selectedBranch) {
                        handleBranchChange(res.data.data.length ? res.data.data[0].uuid : "", false);
                    } else {
                        let match = false;
                        for (let i = 0; i < res.data.data.length; i++) {
                            const element = res.data.data[i];
                            if (selectedBranch == element.uuid) {
                                match = true;
                                if (branchFromURL) {
                                    setModalVisible(false);
                                    localStorage.setItem("branchReload", 0);            
                                }
                                break;
                            }
                        }
                        if (!match) {
                            handleBranchChange(res.data.data.length ? res.data.data[0].uuid : "", true);
                        }
                    }
                }
                setApiLoading(false);
            })
            .catch((err) => {
                showGeneralError(err);
            });
    }, []);

    function handleBranchChange($branch, $reload = false) {
        if ($branch != selectedBranch || shippingType != selectedShippingType ) {
            setSelectedBranch($branch);
            localStorage.setItem("branchid", $branch);
            localStorage.setItem("shippingType", shippingType);
            localStorage.setItem("branchReload", 1);
            if ($reload) {
                window.location = window.location.origin + window.location.pathname
            }
        }
    }

    if (branches.length != 0) {
        return (
            <>
                {/* {announcements && announcements[0] ? renderAnnouncementModal() : null} */}
                <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)} centered scrollable>
                    <ModalHeader toggle={() => setModalVisible(false)}>Please select a branch</ModalHeader>
                    <ModalBody>
                        <Nav tabs>
                            {shippingTypeOption.map((item) => {
                                return (
                                    <NavItem key={item.code}>
                                        <NavLink className={classnames({ active: shippingType == item.code })} onClick={() => { setShippingType(item.code); }} >
                                            {item.name}
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>
                        {shippingType == 1 ? pickupBranches.map((item) => {
                            return (
                                <ul
                                    key={item.uuid}
                                    className="menu menu--layout--classic c-pointer"
                                    style={{ width: "100%", padding: 0 }}
                                    onClick={() => {
                                        handleBranchChange(item.uuid, true);
                                        setModalVisible(false);
                                    }}
                                >
                                    <li>
                                        <a style={{ padding: "15px 15px" }}>
                                            {item.name} {item.uuid == selectedBranch ? "(Selected)" : ""}
                                            <br />
                                            <div style={{ paddingTop: "3px" }}>
                                                <small>
                                                    <i className="fas fa-map-marker-alt"></i>&nbsp;{item.address},&nbsp;{item.postcode}
                                                    ,&nbsp;
                                                    {item.city},&nbsp;{item.state.name},&nbsp;{item.country.name}
                                                </small>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            );
                        }) :
                            shippingType == 2 ? branches.map((item) => {
                                return (
                                    <ul
                                        key={item.uuid}
                                        className="menu menu--layout--classic c-pointer"
                                        style={{ width: "100%", padding: 0 }}
                                        onClick={() => {
                                            handleBranchChange(item.uuid, true);
                                            setModalVisible(false);
                                        }}
                                    >
                                        <li>
                                            <a style={{ padding: "15px 15px" }}>
                                                {item.name} {item.uuid == selectedBranch ? "(Selected)" : ""}
                                                <br />
                                                <div style={{ paddingTop: "3px" }}>
                                                    <small>
                                                        <i className="fas fa-map-marker-alt"></i>&nbsp;{item.address},&nbsp;{item.postcode}
                                                        ,&nbsp;
                                                        {item.city},&nbsp;{item.state.name},&nbsp;{item.country.name}
                                                    </small>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                );
                            }) : null}
                    </ModalBody>
                </Modal>
                <div className="site-header__topbar topbar">
                    <div className="topbar__container container">
                        <div className="topbar__row">
                            <div className="topbar__item">
                                {apiLoading ? (
                                    <div className="block-empty-page custom-topbar-loader">
                                        <BlockLoader />
                                    </div>
                                ) : (
                                    <label className="" style={{ color: "#fff", margin: "0" }}>
                                        <span style={{ fontWeight: "700" }}>
                                            Visitor:&nbsp;{miscInfo?.visitor?.year_visitor_session_count} |{" "}
                                            {miscInfo?.visitor?.visitor_session_count}
                                        </span>
                                    </label>
                                )}
                            </div>
                            <div className="topbar__spring" />
                            <div className="topbar__item">
                                {apiLoading ? (
                                    <div className="block-empty-page custom-topbar-loader">
                                        <BlockLoader />
                                    </div>
                                ) : window.location.pathname == "/checkout" ? null : (
                                    <label
                                        className="c-pointer"
                                        onClick={() => {
                                            setModalVisible(true);
                                        }}
                                        style={{ color: "#00bcb4", margin: "0" }}
                                    >
                                        <i
                                            className="fas fa-map-marker-alt"
                                            style={{ paddingRight: "5px", fontWeight: "700", fill: "#fff", color: "#fff" }}
                                        ></i>
                                        <span style={{ fontWeight: "700", color: "#fff" }}>
                                            Branch:&nbsp;
                                            {branches.map((item) => {
                                                if (item.uuid == selectedBranch) {
                                                    return item.name;
                                                }
                                            })}
                                        </span>
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}
