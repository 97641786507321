import { addToCart, removeCartItem } from "../../api/cart";
import { toast } from "react-toastify";
import { CART_UPDATE_QUANTITIES, SYNC_CART } from "./cartActionTypes";
import { quickviewClose } from "../../store/quickview";
import store from "../store";
import { checkVisitorID } from "../../services/utils";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export const syncCart = (list) => ({
    type: SYNC_CART,
    list,
});

export function cartAddItem(product, variant, addOn, qty = 1, eventInfo, history) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            let data = {
                product_uuid: product.uuid,
                variant_group_uuid: variant,
                quantity: qty,
            };

            if (addOn) {
                for (let i = 0; i < addOn.length; i++) {
                    data["addons[" + i + "][uuid]"] = addOn[i].uuid;
                    data["addons[" + i + "][index]"] = addOn[i].index;
                    data["addons[" + i + "][qty]"] = addOn[i].qty;
                }
            }

            if (eventInfo) {
                data["event_uuid"] = eventInfo.uuid;
                data["parent_hash"] = eventInfo.parentHash;
            }

            addToCart(data)
                .then((res) => {
                    dispatch(syncCart(res.data));
                    if (history && eventInfo == null) {
                        // if on cart page change qty, no need toast msg
                        toast.success(`"${product.name.substring(0, 45) + (product.name.length > 45 ? "..." : "")}" added to cart`, {
                            autoClose: 3000,
                            onClick: () => {
                                history.push("/cart");
                                if (store.getState().quickview.open) {
                                    //if quickview open, close it before redirect to cart page
                                    dispatch(quickviewClose());
                                }
                            },
                        });
                    }
                    resolve(res.data);
                })
                .catch((err) => {
                    if (err.http_code == 422 && err.data) {
                        if (err.data.errors?.["event.pwp_sub_item_max"]) {
                            toast.error("You can only select up to " + err.data.errors?.["event.pwp_sub_item_max"]?.param[1] + " items.");
                        }
                        if (err.data.errors?.["variant_group.out_stock"]) {
                            toast.error("Out of Stock.");
                        }
                        if (err.data?.errors?.["vid.not_found"] || err.data?.errors?.["uuid.invalid"]) {
                            cookies.remove("cartvid");
                            checkVisitorID("cart");
                        }
                    }
                    if (err.data) reject(err.data);
                });
        });
}

export function cartRemoveItem(item, qty) {
    return (dispatch) =>
        new Promise((resolve) => {
            let data = {
                product_uuid: item.product?.uuid,
                variant_group_uuid: item.variantGroupID,
                quantity: qty,
            };

            if (item.selected_addons && item.selected_addons?.length > 0) {
                for (let i = 0; i < item.selected_addons.length; i++) {
                    data["addons[" + i + "][uuid]"] = item.selected_addons[i].uuid;
                    data["addons[" + i + "][index]"] = item.selected_addons[i].index;
                    data["addons[" + i + "][qty]"] = item.selected_addons[i].qty;
                }
            }

            if (item.sub_event_info && item.parent_hash) {
                data["event_uuid"] = item.sub_event_info.uuid;
                data["parent_hash"] = item.parent_hash;
            }

            // if (item.options ) {
            //     for (let i = 0; i < item.options.length; i++) {
            //         data.append("add_on[" + i + "][id]", item.options[i].addon_detail.hash_id);
            //         data.append("add_on[" + i + "][quantity]", item.options[i].addon_quantity);
            //     }
            // }

            removeCartItem(data).then((res) => {
                dispatch(syncCart(res.data));
                resolve(res.data);
            });
        });
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        });
}
