// react
import React from 'react';
 

export default function FooterThirdParty() {
    return (
        <div className="site-footer__widget footer-thirdparty">        
            <h5 className="footer-thirdparty__title">Payment Method</h5>   
            <img src="images/thirdpartys/hor-fpx.png" alt="logo" className="footer-logo1" />
            <h5 className="footer-thirdparty__title">Delivery Services</h5>   
            <img src="images/thirdpartys/shipping.png" alt="logo" className="footer-logo1"/>
        </div>
    );
}
