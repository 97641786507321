export const userTitleList = [
    { value: "mr", label: "Mr" },
    { value: "mrs", label: "Mrs" },
    { value: "ms", label: "Ms" },
    { value: "other", label: "Other" },
];

export const addressTypeList = [
    { code: "0", name: "Shipping" },
    { code: "1", name: "Billing" },
];

export const orderStatusList = [
    { code: "0", name: "Pending" },
    { code: "10", name: "Processing" },
    { code: "15", name: "Waiting Restock" },
    { code: "20", name: "Ready" },
    { code: "25", name: "Delivering" },
    { code: "30", name: "Completed" },
    { code: "130", name: "Cancelled" },
    { code: "100", name: "Pending Refund" },
    { code: "120", name: "Refunded" },
    { code: "110", name: "Refund Cancelled" },
    { code: "140", name: "Failed Payment" },
];

export const groupOrderStatusList = [
    { code: "0", name: "To Pay" },
    { code: "0,140", name: "To Ship" },
    { code: "25", name: "To Receive" },
    { code: "30", name: "Completed" },
    { code: "130,100,120,110", name: "Refund/Cancel" },
];

export const paymentStatusList = [
    { code: "0", name: "Pending Payment" },
    { code: "10", name: "Paid" },
    { code: "20", name: "Payment Rejected" },
    { code: "30", name: "Payment Cancelled" },
];

export const voucherUsageTypeList = [
    { code: "0", name: "All" },
    { code: "1", name: "Member" },
    { code: "2", name: "Guest" },
];

export const eventTypeList = [
    { code: 10, name: "Discount" },
    { code: 15, name: "Shocking Sales" },
    { code: 20, name: "Voucher" },
    { code: 30, name: "PWP Combo" },
    { code: 40, name: "PWP Bundle" },
    { code: 50, name: "PWP Free Gift" },
];

export const locationTypeList = [
    { code: 0, name: "country" },
    { code: 1, name: "state" },
    { code: 2, name: "postcode" },
];

export const commTypeList = [
    { code: "10", name: "Bonus Overriding" },
    { code: "20", name: "Cashback" },
    { code: "30", name: "Bonus Performance" },
    { code: "40", name: "Sponsorship New Member" },
    { code: "50", name: "Performance Networking" },
    { code: "60", name: "Leadership" },
];
export const rankCodeList = [
    { code: "300", name: "Member" },
    { code: "400", name: "VVIP Member" },
    { code: "500", name: "Executive" },
    { code: "600", name: "Platinum" },
    { code: "700", name: "Legend" },
];
export const shippingTypeList = [
    { code: "2", name: "Delivery" },
    { code: "1", name: "Self Pickup" },
];

export const kycStatusList = [
    { code: "0", name: "Pending" , color: "warning" },
    { code: "10", name: "Approved" , color: "success" },
    { code: "90", name: "Rejected" , color: "danger" },
];