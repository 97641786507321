// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { getSEOSettings } from "../../api/general";

function SitePageMaintenance() {
    return (
        <div className="block" style={{ height: "90vh" }}>
            <Helmet>
                <title>{`Under Maintenance — ${getSEOSettings("meta_title")}`}</title>
            </Helmet>
            <div className="site-header__logo justify-content-center pt-3">
                <img src={"images/logos/logo-v2.png"} alt="logo" className="header-logo" />
            </div>
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="not-found">
                    <img className="img-fluid" style={{ height: "50vh" }} src={"/images/website-maintenance.png"} />
                    <div className="not-found__content">
                        <h1 className="not-found__title">Under Maintenance 🛠</h1>
                        <p className="not-found__text">Sorry for the inconvenience but we're performing some maintenance at the moment.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageMaintenance;
