import Cookies from "universal-cookie";
import moment from "moment";
import { appendFormData, checkVisitorID, cookiesSetting, getAuthHeader } from "../services/utils";
import store from "../store";
const axios = require("axios");
const cookies = new Cookies();

export function login(data) {
    data.sync_cart = 1;
    let formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/login", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                saveTokenToCookies(data.data);
                localStorage.removeItem("shipping");
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.status == 500) {
                            cookies.remove("cartvid");
                            checkVisitorID();
                        }
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export function saveTokenToCookies(data) {
    let setting = process.env.NODE_ENV == "production" ? cookiesSetting : {};

    var expiryDate = moment.utc().add(data.expires_in, "seconds");
    cookies.set("access_token", data.access_token, { ...setting, path: "/", expires: expiryDate._d });
    let currentVid = cookies.get("cartvid");
    cookies.set("cartvid", currentVid, {
        ...setting,
        domain: process.env.REACT_APP_DOMAIN,
        path: "/",
        expires: expiryDate._d,
    });
}

function logoutSuccess(history) {
    removeUserFromStorage();
    if (history) history.replace("/login");
    else window.location.pathname = "/login";
}

export function logout(history) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/logout";

    return new Promise(async (resolve, reject) => {
        axios
            .post(url, null, {
                headers: { ...getAuthHeader(), "Content-Type": "application/json" },
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                logoutSuccess(history);
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                logoutSuccess(history);
                if (error.response) {
                    if (error.response.data) {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export function register(data) {
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/register", formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function forgetPassword(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/forgot-password";
    data.portal_type = process.env.REACT_APP_PORTAL_TYPE;
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export async function resetPassword(data) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/reset-password";
    data.portal_type = process.env.REACT_APP_PORTAL_TYPE;
    var formData = appendFormData(data);

    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                }
            });
    });
}

export function getAccessToken() {
    let tokenData = cookies.get("access_token");

    if (tokenData) {
        return tokenData;
    } else {
        return 0;
    }
}

export function removeUserFromStorage() {
    let user = store.getState().user;
    if (window.OneSignal) window.OneSignal.setExternalUserId(null, user?.profile?.oneSignalHashUuid);
    cookies.remove("access_token", { path: "/" });
}
