import React, { useEffect, useState } from "react";
import { getPageBySlug } from "../../api/page";
import { redirectTo404 } from "../../services/utils";
import BlockLoader from "../blocks/BlockLoader";
import WYSWYG from "../shared/Wyswyg";
import { Link } from "react-router-dom";
import moment from "moment";

function SitePageBlog(props) {
    const { blogSlug, layout } = props;
    const [page, setPage] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {       
        setLoading(true);
        getPageBySlug(blogSlug)
            .then((res) => {
                setPage(res.data);
                setLoading(false);
            })
            .catch((err) => {
                if (err.http_code == 404) {
                    redirectTo404();
                }
            });
    }, [blogSlug]);

    return (
        <React.Fragment>
            {loading ? (
                <div className="block-empty-page">
                    <BlockLoader />
                </div>
            ) : (
                <div style={{ backgroundColor: "#dff6f5" }}>
                    <div className="p-3 row no-gutters justify-content-center">
                        <div className="col-md-12 col-lg-9 col-xl-8">
                            <div className={`block post post--layout--full`}>
                                <div className={`post__header post-header post-header--layout--full`}>
                                    <h1 className="post-header__title pt-2">{page.title}</h1>
                                    <div className="post-header__meta">
                                        <div className="post-header__meta-item">{moment(page.updated_at).format("LLL")}</div>
                                    </div>
                                </div>
                                {page.image && page.image?.url ? (
                                    <div className="post__featured">
                                        <img src={page.image?.url} alt="blog_image" />
                                    </div>
                                ) : null}
                                <div className="post__content block">
                                    <WYSWYG page={page} layout="blog" />
                                    <div className="container post__footer">
                                        <div className="post__tags-share-links">
                                            <div className="post__tags tags">
                                                <div className="tags__list">
                                                    {page.tags?.map((tag) => {
                                                        return <Link to={"/blog?tag=" + tag}>{tag}</Link>;
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default SitePageBlog;
