import React from "react";
import Editor from "@react-page/editor";
import { imagePlugin } from "@react-page/plugins-image";
import slate from "@react-page/plugins-slate";
import background from "@react-page/plugins-background";
import video from "@react-page/plugins-video";
import divider from "@react-page/plugins-divider";
import spacer from "@react-page/plugins-spacer";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { getSEOSettings } from "../../api/general";
import { returnResponsiveImg, getNoImg } from "../../services/utils";

function WYSWYG(props) {
    const { page, layout } = props;
    const cellPlugins = [slate(), video, divider, spacer, imagePlugin(), background()];

    return (
        <React.Fragment>
            {layout == "section" ? null : (
                <Helmet>
                    <title>{page.meta_title ? page.meta_title : `${page.title} - ${getSEOSettings("meta_title")}`}</title>
                    <meta name="description" content={page.meta_description ? page.meta_description : ""} />
                    <meta name="og:title" content={page.meta_title ? page.meta_title : `${page.title} - ${getSEOSettings("meta_title")}`} />
                    <meta name="keywords" content={page.meta_keywords} />
                    <meta name="og:description" content={page.meta_description ? page.meta_description : ""} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:image" content={page.image ? returnResponsiveImg(page.image) : getNoImg("small")} />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location.href} />
                    <meta
                        property="twitter:title"
                        content={page.meta_title ? page.meta_title : `${page.title} - ${getSEOSettings("meta_title")}`}
                    />
                    <meta property="twitter:description" content={page.meta_description ? page.meta_description : ""} />
                    <meta property="twitter:image" content={page.image ? returnResponsiveImg(page.image) : getNoImg("small")} />
                </Helmet>
            )}
            {page.content && Object.keys(page.content) && page.content.rows?.length > 0 ? (
                <div
                    className={classNames("container", {
                        "wyswyg-wrapper": layout === "page",
                        "wyswyg-section px-sm-0": layout === "section",
                        "wyswyg-wrapper pt-2 pb-0": layout === "blog",
                    })}
                >
                    <Editor value={page.content} cellPlugins={cellPlugins} readOnly />
                </div>
            ) : null}
        </React.Fragment>
    );
}

export default WYSWYG;
