// react
import React from "react";
import { Link } from "react-router-dom";

export default function FooterAboutUs() {
    return (
        <div className="site-footer__widget footer-about">
            {/* <h5 className="footer-about__title">About</h5> */}
            <div className="footer-about__text">
                Tukarkan Produk Harian Anda Sekarang <br />
                Kepada Produk LEBIH PATUH SYARIAH{" "}
            </div>
            <Link to="/">
                <img src="images/logos/logo-v2.png" alt="logo" className="footer-logo" />
            </Link>
        </div>
    );
}
