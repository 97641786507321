// react
import React, { useEffect, useState } from "react";

// third-party
import { connect } from "react-redux";
import { Modal } from "reactstrap";

// application
import { Cross20Svg } from "../../svg";
import { shippingInfoClose } from "../../store/shippingInfo";
import Currency from "./Currency";
import { getCountryList, getStates } from "../../api/general";
import { useSelector } from "react-redux";
import { locationTypeList } from "../../data/generalData";
import { groupBy } from "../../services/utils";
const weightUnit = " kg";

function ShippingInfomation(props) {
    const { open, shippingInfoClose, info, logisticInfo } = props;
    let freeShippingSetting = logisticInfo?.free_shipping_settings;
    const [freeShippingInfo, setFreeShippingInfo] = useState([]);
    const [countryList, setCountryList] = useState(useSelector((state) => state?.generalInfo?.countries));

    function gramToKilogram(gramValue) {
        return parseFloat(gramValue) / 1000;
    }

    useEffect(() => {
        if (!countryList || !countryList[0]) {
            getCountryList().then((res) => {
                if (res.data?.data) setCountryList(res.data.data);
            });
        }
    }, []);

    // only load when user on checkout page (depends check shipping information link on where)
    useEffect(() => {
        //should only run once
        if (freeShippingSetting && countryList[0] && window.location.pathname == "/checkout" && freeShippingInfo.length == 0) {
            //    [0] = counrty code, [1] = value = country code-state code#location type:eligible amount to get free shipping
            //    example :(MY-04#1:40)
            let infoObjArr = Object.entries(freeShippingSetting);
            locationMapping(infoObjArr);
        }
    }, [freeShippingSetting, countryList, window.location.pathname]);

    async function locationMapping(locationArrKey) {
        const freeShippingList = [];

        let promise = await locationArrKey.map(async (current) => {
            let locationInfo = [];
            let countryStates = [];
            countryStates = (await getStates(current[0])).data.data;
            await Object.entries(current[1]).map((value) => {
                //[0] = state iso_3166_2 code, [1] = location type code
                let place = value[0].split("#");
                let eligibleAmt = value[1];
                locationInfo.push({
                    locationType: locationTypeList.find((item) => item.code == place[1])?.name,
                    locationName:
                        place[1] == 0
                            ? countryList.find((country) => country.cca2 == place[0])?.name
                            : place[1] == 1
                            ? countryStates?.find((item) => item.iso_3166_2 == place[0])?.formal_name
                            : place[0],
                    eligibleAmt: eligibleAmt,
                });
            });

            await freeShippingList.push({
                country: countryList.find((country) => country.cca2 == current[0])?.name,
                eligibleAmtInfo: groupBy(locationInfo, (location) => location.eligibleAmt),
            });
        });

        Promise.all(promise).then(() => {
            setFreeShippingInfo(freeShippingList);
        });
    }

    const renderRangeList = (ranges) => {
        let moreThanWeight = 0;
        if (ranges[ranges.length - 1].is_unlimited_weight === "1") {
            moreThanWeight = ranges
                .slice(0, ranges.length - 1)
                .reduce((totalWeight, item) => totalWeight + parseFloat(parseFloat(item.weight) * parseInt(item.unit)), 0);
        }
        return ranges.map((range, index) => {
            return (
                <>
                    <span>{renderRangeRow(range, index, moreThanWeight, ranges)}</span>
                    {index <= ranges.length - 1 ? <br /> : null}
                </>
            );
        });
    };

    const renderRangeRow = (range, index, moreThanWeight, ranges) => {
        let prevGram = 0;

        for (let i = 0; i < ranges.length; i++) {
            if (i == index) {
                break;
            }
            prevGram += parseFloat(parseFloat(ranges[i].weight) * parseFloat(ranges[i].unit));
        }
        // is unlimited weight condition
        if (range.is_unlimited_weight === "1") {
            // first and only so just direct show price
            if (index == 0) {
                return (
                    <div key={index}>
                        <Currency value={range.rate} />
                    </div>
                );
            } else {
                // if more than xx direct additional RM xx
                return (
                    <div key={index}>
                        {"> " + gramToKilogram(moreThanWeight) + weightUnit + " :"}
                        <br />
                        Additional <Currency value={range.rate} />
                    </div>
                );
            }
        } else if (range.is_unlimited_unit === "1") {
            return (
                <div key={index}>
                    {index == 0 ? "" : "> " + gramToKilogram(prevGram + 1) + weightUnit + " :"}
                    {index !== 0 ? (
                        <>
                            <br />
                            {"+"}
                        </>
                    ) : null}
                    <Currency value={range.rate} />
                    {"/" + gramToKilogram(range.weight) + weightUnit}
                </div>
            );
        } else {
            if (index == 0) {
                return (
                    <div key={index}>
                        {"First " + gramToKilogram(parseFloat(range.weight) * parseFloat(range.unit)) + weightUnit} :<br />
                        <Currency value={range.rate} />
                    </div>
                );
            } else {
                return (
                    <div key={index}>
                        Following {gramToKilogram(prevGram + 1)}
                        {" - "}
                        {gramToKilogram(parseFloat(range.weight) * parseFloat(range.unit)) + gramToKilogram(prevGram) + weightUnit + " :"}
                        <br />
                        +<Currency value={range.rate} />/{gramToKilogram(range.weight) + weightUnit}
                    </div>
                );
            }
        }
    };

    const renderLocation = (data, index, listLength) => {
        return (
            data.location.name +
            (data.location.type == 1 || data.location.type == 2 ? " (" + data.country?.name + ")" : "") +
            (index < listLength - 1 ? ", " : "")
        );
    };

    return (
        <Modal isOpen={open} toggle={shippingInfoClose} centered size="xl">
            <div className="quickview">
                <button className="quickview__close" type="button" onClick={shippingInfoClose}>
                    <Cross20Svg />
                </button>
                <div className="d-flex justify-content-between">
                    <h5>Shipping Fee Information</h5>
                    <span>Volumetric Weight : {logisticInfo?.volumetric_weight_factor}</span>
                </div>

                <div className="card-table shipping-table overflow-auto">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Zone</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Fares</th>
                            </tr>
                        </thead>
                        {info?.length > 0 ? (
                            <tbody>
                                {info?.map((zone) => {
                                    return (
                                        <tr key={zone.uuid}>
                                            <td>{zone.name}</td>
                                            <td>
                                                {zone.origin_list.map((origin, index) => {
                                                    return renderLocation(origin, index, zone.origin_list.length);
                                                })}
                                            </td>
                                            <td>
                                                {zone.dest_list.map((dest, index) => {
                                                    return renderLocation(dest, index, zone.dest_list.length);
                                                })}
                                            </td>
                                            <td>{renderRangeList(zone.ranges)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        Not yet support shipping for now
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
                <br />
                {freeShippingInfo && freeShippingInfo.length > 0 ? (
                    <>
                        <h5>Free Shipping Information</h5>
                        <div className="card-table shipping-table overflow-auto">
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Country</th>
                                        <th>Eligible Amount for free shipping</th>
                                        <th>Location (Country/State/Postcode)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {freeShippingInfo?.map((item) => {
                                        return item.eligibleAmtInfo?.map((data, index) => {
                                            return (
                                                <tr>
                                                    {index == 0 ? <td rowSpan={item.eligibleAmtInfo.length}>{item.country}</td> : null}
                                                    <td style={{ paddingLeft: 12 }}>
                                                        <Currency value={data.name} />
                                                    </td>
                                                    <td>
                                                        {data.value.map((info, infoIndex) => {
                                                            return info.locationName + (infoIndex < data.value.length - 1 ? ", " : "");
                                                        })}
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : null}
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    open: state.shippingInfo.open,
    info: state.shippingInfo.info,
    freeShippingSetting: state.generalInfo?.info?.logistic_info?.free_shipping_settings,
});

const mapDispatchToProps = {
    shippingInfoClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingInfomation);
