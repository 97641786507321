export default {
    name: 'Int3tree',
    fullName: 'Int3tree',
    companyName: "Int3tree",
    url: 'https://uat.int3tree.com',
    color: '#0871A1',
    meta_title: 'Int3tree',
    meta_description: '',
    meta_keyword: '',
    author: {
        name: "Ant Internet Shd Bhd",
        url: "https://ant-internet.com/",
        registration_number: "1199944-V",
    },
    contacts: {
        address: '715 Fake Street, New York 10021 USA',
        email: 'stroyka@example.com',
        phone: '(800) 060-0730',
    },
};
