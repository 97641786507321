// react
import React from "react";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterNewsletter from "./FooterNewsletter";
import FooterThirdParty from "./FooterThirdParty";

import moment from "moment";
import FooterAbout from "./FooterAbout";
import Fab from "./Fab";
import theme from "../../data/theme";

export default function Footer(props) {
    const { socialLinks, storeInfo } = props;

    const informationLinks = [
        { title: "About Us", url: "/about-us" },
        { title: "Contact Us", url: "/contact-us" },
        // { title: "Policy", url: "/policy" },
        // { title: "FAQ", url: "/faq" },
    ];

    const accountLinks = [
        { title: "Store Location", url: "" },
        { title: "Order History", url: "" },
        { title: "Wish List", url: "" },
        { title: "Newsletter", url: "" },
        { title: "Specials", url: "" },
        { title: "Gift Certificates", url: "" },
        { title: "Affiliate", url: "" },
    ];

    const footerBottom = () => {
        return (
            <div className="site-footer__bottom">
                <div className="site-footer__copyright footer-p">
                    <p className="footer-p mb-0">
                        Copyright <i className="far fa-copyright" aria-hidden="true" /> {moment().format("YYYY")}{" "}
                        {storeInfo?.company_info?.name ?? theme.companyName}{" "}
                        {storeInfo?.company_info?.reg_num ? "(" + storeInfo?.company_info?.reg_num + ")" : null}. All Rights Reserved.
                        {/* <label className="p-3 d-none d-lg-inline">|</label>
                        <br className="d-lg-none"></br>
                        <a id="antinternet" target="_blank" href="https://ant-internet.com" className="develop-info c-pointer">
                            Design and Development by Ant Internet Sdn Bhd.
                        </a> */}
                        <label className="p-3 d-none d-lg-inline"></label>
                        <br className="d-lg-none"></br>
                        Visitor: {storeInfo?.misc_stats?.visitor?.year_visitor_session_count} |{" "}
                        {storeInfo?.misc_stats?.visitor?.visitor_session_count}
                    </p>
                </div>
                <div className="site-footer__social-icons">
                    <ul className="social-icons-list form-row mb-0">
                        {socialLinks?.facebook_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.facebook_url} target="_blank">
                                    <i className="fab fa-facebook-f social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.twitter_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.twitter_url} target="_blank">
                                    <i className="fab fa-twitter social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.youtube_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.youtube_url} target="_blank">
                                    <i className="fab fa-youtube social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.instagram_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.instagram_url} target="_blank">
                                    <i className="fab fa-instagram social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.tiktok_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.tiktok_url} target="_blank">
                                    <i className="fab fa-tiktok social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}

                        {socialLinks?.flickr_url ? (
                            <li className="social-icons">
                                <a href={socialLinks?.flickr_url} target="_blank">
                                    <i className="fab fa-flickr social-icon" aria-hidden="true"></i>
                                </a>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-3">
                            <FooterAbout />
                        </div>

                        <div className="col-12 col-md-5 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks} dynamicFooter={storeInfo?.footer?.content?.items} />
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                            <FooterContacts storeInfo={storeInfo} />
                        </div>

                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterThirdParty />
                        </div>
                        {/*
                        <div className="col-6 col-md-3 col-lg-2">
                          <FooterLinks title="My Account" items={accountLinks} />
                        </div> */}
                        {/* <div className="col-12 col-md-12 col-lg-4">
                          <FooterNewsletter />
                        </div> */}
                    </div>
                </div>
                {footerBottom()}
            </div>
            <Fab whatsappNumber={storeInfo?.contact_info?.whastapp} />
        </div>
    );
}
